let AUTH_URL,
    CORE_URL,
    NAILSPA_URL,
    NOTIFICATION_URL,
    FILE_URL_API,
    FILE_URL,
    DEFAULT_AVATAR,
    DEFAULT_IMG,
    WEBSITE_URL,
    FIREBASE_URL;
let DOMAIN = 'quanlyspa.jadespa.vn';

const hName = window.location.hostname;
const jadespaStaging = hName.includes('staging.quanlyspa.jadespa.vn');
const jadespaDev = hName.includes('localhost') || hName.includes('dev.quanlyspa.jadespa.vn');
const jadespaProduction = hName.includes('quanlyspa.jadespa.vn');
const ghmProduction = hName.includes('quanlyspa.ghmsoft.vn');

if (jadespaDev) {
    AUTH_URL = 'https://devapi.jadespa.vn/auth';
    CORE_URL = 'https://devapi.jadespa.vn/core/';
    NAILSPA_URL = 'https://devapi.jadespa.vn/spa/';
    NOTIFICATION_URL = 'https://devapi.jadespa.vn/notification';
    FILE_URL_API = 'https://devapi.jadespa.vn/file/api/v1/';
    FILE_URL = 'https://devapi.jadespa.vn/file';
    DEFAULT_AVATAR = 'https://devapi.jadespa.vn/file/uploads/images/no-avatar.png';
    DEFAULT_IMG = 'https://devapi.jadespa.vn/file/uploads/images/no-image.png';
    WEBSITE_URL = 'https://devapi.jadespa.vn/websiteapi/';
    FIREBASE_URL = 'https://devapi.jadespa.vn/firebase/';
} else if (jadespaStaging || ghmProduction || jadespaProduction) {
    AUTH_URL = 'https://api.jadespa.vn/auth';
    CORE_URL = 'https://api.jadespa.vn/core/';
    NAILSPA_URL = 'https://api.jadespa.vn/spa/';
    NOTIFICATION_URL = 'https://api.jadespa.vn/notification';
    FILE_URL_API = 'https://api.jadespa.vn/file/api/v1/';
    FILE_URL = 'https://api.jadespa.vn/file';
    DEFAULT_AVATAR = 'https://api.jadespa.vn/file/uploads/images/no-avatar.png';
    DEFAULT_IMG = 'https://api.jadespa.vn/file/uploads/images/no-image.png';
    WEBSITE_URL = 'https://api.jadespa.vn/websiteapi/';
    FIREBASE_URL = 'https://api.jadespa.vn/firebase/';

    if (ghmProduction) DOMAIN = 'quanlyspa.ghmsoft.vn';
}
// else if (jadespaProduction) {
//     AUTH_URL = 'https://ghmspaauth.ghmsoft.vn';
//     CORE_URL = 'https://ghmspacore.ghmsoft.vn/api/v1/';
//     NAILSPA_URL = 'https://ghmspaapi.ghmsoft.vn/api/v1/';
//     NOTIFICATION_URL = 'https://ghmspanotification.ghmsoft.vn';
//     FILE_URL_API = 'https://ghmspafile.ghmsoft.vn/api/v1/';
//     FILE_URL = 'https://ghmspafile.ghmsoft.vn';
//     DEFAULT_AVATAR = 'https://ghmspafile.ghmsoft.vn/uploads/images/no-avatar.png';
//     DEFAULT_IMG = 'https://ghmspafile.ghmsoft.vn/uploads/images/no-image.png';
//     WEBSITE_URL = 'https://ghmspawebsite.ghmsoft.vn/api/v1/';
//     FIREBASE_URL = 'https://ghmspafirebase.ghmsoft.vn/api/v1/';
// }

export {
    AUTH_URL,
    CORE_URL,
    NAILSPA_URL,
    NOTIFICATION_URL,
    FILE_URL_API,
    FILE_URL,
    DEFAULT_AVATAR,
    DEFAULT_IMG,
    WEBSITE_URL,
    FIREBASE_URL,
    DOMAIN
};

export const pageSizes = [10, 20, 50];
export const currentLanguage = 'vi-VN';
export const tabs = [
    {
        id: 0,
        languageId: 'vi-VN',
        icon: 'globe',
        text: 'Tiếng Việt'
    },
    {
        id: 1,
        languageId: 'en-US',
        icon: 'globe',
        text: 'English'
    }
];
export const DEFAULT_NO_IMG = '/uploads/images/no-image.png';

export const PAGE_SIZE = 10;
export const RECORDS_SELECT = [5, 10, 20, 50, 100, 500];

export const CLIENT_ID = 'GHMSOFTCLIENT';
export const SCOPES =
    'GHM_Core GHM_Gateway GHM_Notification GHM_FileManagement GHM_FireBase GHM_NailSpa GHM_Website offline_access openid profile email';
// export const DOMAIN = 'vj.soft.comm'

// export const DOMAIN = 'tuoispa.com'; // admin cao nhất
export const DOMAIN_TYPE = '0';
export const CLIENT_SECRET = 'GHMSOFT';

export const DEVEXTREME_BASEURL = 'https://cdn3.devexpress.com/jslib/19.2.3/css/';

// khach hang gioi thieu || nhan vien gioi thieu
export const KHGT = 'KHGT';
export const NVGT = 'NVGT';

export const FORMAT_TIME_BYLANG = {
    'vi-VN': 'DD/MM/yyyy',
    'en-US': 'MM/DD/yyyy'
};
export const FORMAT_TIMEDATE_BYLANG = {
    'vi-VN': 'HH:mm DD/MM/YYYY',
    'en-US': 'HH:mm MM/DD/yyyy'
};
// id the tra truoc va the dich vu
export const TTT = 'TTT';
export const TDV = 'TDV';
export const D = 'D';
export const TM = 'TM';
export const TTTOTHER = 'TTTOTHER';
export const TDVOTHER = 'TDVOTHER';
export const GIFT = 'GIFT';

export const EXCEPT_ROUTER = [
    '/config/usercustom',
    '/login',
    '/report',
    '/report-customerServiceCard',
    '/report-customerServiceCard-detail',
    '/report-commissionne',
    '/report-total-revenue',
    '/warehouse',
    '/category/brand',
    '/customer/point',
    '/promotion-card',
    '/',
    '/website/:websiteId',
    '/album/:albumId',
    '/menu/:menuId',
    '/subscribe/:subscribeId',
    '/customerFeedback/:customerFeedbackId',
    '/partner/:partnerId',
    '/feedback/:feedbackId',
    '/service-website/:serviceId',
    '/product-website/add',
    '/booking/edit/:id',
    '/news/:id',
    '/project/add',
    '/project',
    '/project/:id',
    '/job/add',
    '/job/:id',
    '/recruitment/add',
    '/recruitment/:id',
    '/project-category/add',
    '/project-category/:id',
    '/testPage',
    '/customer/custom-card/:branchId/:cardId',
    '/customer/custom-card/:customerId',
    '/service/:id'
];

export const MONTH_OF_YEAR = [
    { id: 1, 'name-vi-VN': 'Tháng 1', value: 1, 'name-en-US': 'January' },
    { id: 2, 'name-vi-VN': 'Tháng 2', value: 2, 'name-en-US': 'February' },
    { id: 3, 'name-vi-VN': 'Tháng 3', value: 3, 'name-en-US': 'March' },
    { id: 4, 'name-vi-VN': 'Tháng 4', value: 4, 'name-en-US': 'April' },
    { id: 5, 'name-vi-VN': 'Tháng 5', value: 5, 'name-en-US': 'May' },
    { id: 6, 'name-vi-VN': 'Tháng 6', value: 6, 'name-en-US': 'June' },
    { id: 7, 'name-vi-VN': 'Tháng 7', value: 7, 'name-en-US': 'July' },
    { id: 8, 'name-vi-VN': 'Tháng 8', value: 8, 'name-en-US': 'August' },
    { id: 9, 'name-vi-VN': 'Tháng 9', value: 9, 'name-en-US': 'September' },
    { id: 10, 'name-vi-VN': 'Tháng 10', value: 10, 'name-en-US': 'October' },
    { id: 11, 'name-vi-VN': 'Tháng 11', value: 11, 'name-en-US': 'November' },
    { id: 12, 'name-vi-VN': 'Tháng 12', value: 12, 'name-en-US': 'December' }
];

export const CUSTOMER_SERVICECARD_USEHISTORY_TYPE = [
    { type: 1, name: '{"vi-VN":"Lịch sử dịch vụ","en-US":"Service History"}' },
    { type: 2, name: '{"vi-VN":"Lịch sử sản phẩm","en-US":"Product History"}' },
    { type: 3, name: '{"vi-VN":"Lịch sử rút tiền","en-US":"Withdraw History"}' }
];
export const BILL_TYPE_NAME = [
    {
        type: 0,
        name: '{"vi-VN":"Hóa đơn thẻ trả trước","en-US":"Prepaid card bill"}'
    },
    { type: 1, name: '{"vi-VN":"Hóa đơn dịch vụ","en-US":"Service bill"}' },
    { type: 2, name: '{"vi-VN":"Hóa đơn sản phẩm","en-US":"Product bill"}' },
    { type: 3, name: '{"vi-VN":"Hóa đơn rút tiền","en-US":"Withdrawal bill"}' },
    {
        type: 4,
        name: '{"vi-VN":"Hóa đơn yêu cầu dịch vụ","en-US":"Service request bill"}'
    },
    {
        type: 5,
        name: '{"vi-VN":"Hóa đơn yêu cầu sản phẩm","en-US":"Product requirements bill"}'
    },
    {
        type: 6,
        name: [
            '{"vi-VN":"Hóa đơn chuyển tiền","en-US":"Transfers bill"}',
            '{"vi-VN":"Hóa đơn nhận tiền","en-US":"Receive bill"}'
        ]
    },
    { type: 7, name: '{"vi-VN":"Hóa đơn thẻ dịch vụ","en-US":"Pay bill"}' },
    { type: 8, name: '{"vi-VN":"Hóa đơn hoàn tiền thẻ dịch vụ","en-US":"Refund service card bill"}' },
    { type: 9, name: '{"vi-VN":"Hóa đơn hoàn tiền thẻ trả trước","en-US":"Refund customer card bill"}' }
];

export const VIDEO_TYPE = [
    { id: 0, name: '{"vi-VN":"Youtube","en-US":"Youtube"}' },
    { id: 1, name: '{"vi-VN":"Vimeo","en-US":"Vimeo"}' }
];

export const ALBUM_TYPE = [
    { id: 0, name: '{"vi-VN":"Ảnh","en-US":"Photo"}' },
    { id: 1, name: '{"vi-VN":"Video","en-US":"Video"}' }
];

export const CONTACT_TYPE = [
    { id: 0, name: '{"vi-VN":"Email","en-US":"Email"}' },
    { id: 1, name: '{"vi-VN":"Số điện thoại","en-US":"Phone Number"}' },
    { id: 2, name: '{"vi-VN":"Mã số thuế","en-US":"TIN"}' },
    { id: 3, name: '{"vi-VN":"KvK","en-US":"KvK"}' }
];

export const ACCOUNT_TYPE = [
    { id: 0, name: '{"vi-VN":"Nhân viên","en-US":"Employee"}' },
    { id: 1, name: '{"vi-VN":"Khách hàng","en-US":"Customer"}' }
];

export const GENDER = [
    { id: 0, name: '{"vi-VN":"Nữ","en-US":"Female"}' },
    { id: 1, name: '{"vi-VN":"Nam","en-US":"Male"}' },
    { id: 2, name: '{"vi-VN":"Khác","en-US":"Other"}' }
];

export const DISCOUNT = [
    { id: 0, name: 'Giảm giá bằng tiền' },
    { id: 1, name: '% Giảm giá' }
];

export const USER_TYPE = [
    { id: 0, name: '{"vi-VN":"Nhân viên","en-US":"Staff"}' },
    { id: 1, name: '{"vi-VN":"Trưởng đơn vị","en-US":"Leader"}' },
    { id: 2, name: '{"vi-VN":"Phó đơn vị","en-US":"ViceLeader"}' }
];

export const IS_TECH = [
    { id: 0, name: '{"vi-VN":"Nhân viên thường","en-US":"Staff"}' },
    { id: 1, name: '{"vi-VN":"Kỹ thuật viên","en-US":"Technicians"}' }
];

export const IS_ALL = [
    { id: 0, name: '{"vi-VN":"Một vài dịch vụ","en-US":"Some service"}' },
    { id: 1, name: '{"vi-VN":"Tất cả","en-US":"ALL service"}' }
];

export const IS_DISCOUNT = [
    { id: 0, name: '{"vi-VN":"Giảm giá bằng tiền","en-US":"discount"}' },
    { id: 1, name: '{"vi-VN":"% giảm giá","en-US":"% discount"}' }
];

export const CATEGORIES = [
    { id: 0, name: '{"vi-VN":"Nhân viên","en-US":"Staff"}' },
    { id: 1, name: '{"vi-VN":"Trưởng đơn vị","en-US":"Leader"}' },
    { id: 2, name: '{"vi-VN":"Phó đơn vị","en-US":"ViceLeader"}' }
];

export const STATUS = [
    { id: 0, name: '{"vi-VN":"Học việc","en-US":"Apprentice"}' },
    { id: 1, name: '{"vi-VN":"Thử việc","en-US":"Probation"}' },
    { id: 2, name: '{"vi-VN":"Chính thức","en-US":"Official"}' },
    { id: 3, name: '{"vi-VN":"Nghỉ thai sản","en-US":"Maternity"}' },
    { id: 4, name: '{"vi-VN":"Thôi việc","en-US":"Discontinue"}' },
    { id: 5, name: '{"vi-VN":"Nghỉ hưu","en-US":"Retirement"}' }
];

export const RECRUIMENT_POSITION = [
    { id: 0, name: '{"vi-VN":"Nhân viên","en-US":"Staff"}' },
    { id: 1, name: '{"vi-VN":"PM","en-US":"PM"}' }
];

export const MARRIED_STATUS = [
    { id: 0, name: '{"vi-VN":"Độc thân","en-US":"Single"}' },
    { id: 1, name: '{"vi-VN":"Đã kết hôn","en-US":"Married"}' },
    { id: 2, name: '{"vi-VN":"Ly thân","en-US":"Separated"}' },
    { id: 3, name: '{"vi-VN":"Ly hôn","en-US":"Divorce"}' }
];

export const FORMAT_TIME = 'YYYY-MM-DD[T]HH:mm:ss';
export const FORMAT_DAY = 'DD-MM-YYYY';
export const FORMAT_DATE_TIME_BYLANG = {
    'vi-VN': 'DD/MM/yyyy[-]HH:mm:ss',
    'en-US': 'MM/DD/yyyy[-]HH:mm:ss'
};
export const FORMAT_DAY_BYLANG = {
    'vi-VN': 'DD/MM/yyyy',
    'en-US': 'MM/DD/yyyy'
};

export const FORMAT_HOUR = 'HH:mm';

export const RESOURCE_DATA = [
    {
        name: '{"vi-VN": "Chưa tới", "en-US": "Yet to come"}',
        id: 0,
        color: '#6C757D'
    },
    {
        name: '{"vi-VN": "Đang chờ", "en-US": "Pending"}',
        id: 1,
        color: '#17A2B8'
    },
    {
        name: '{"vi-VN": "Đang thực hiện", "en-US": "Processing"}',
        id: 2,
        color: '#007BFF'
    },
    {
        name: '{"vi-VN": "Hoàn thành", "en-US": "Completed"}',
        id: 4,
        color: '#28A745'
    },
    {
        name: '{"vi-VN": "Hủy", "en-US": "Cancel"}',
        id: 3,
        color: '#DC3545'
    }
];

export const QUICK_SEARCH = [
    {
        name: 'Theo ngày',
        id: 0
    },
    {
        name: 'Theo tuần',
        id: 1
    },
    {
        name: 'Theo tháng',
        id: 2
    }
];

export const STATUS_SERVICE_NOTE = [
    { id: 0, name: '{"vi-VN":"Chưa thu tiền","en-US":"Not Paid"}' },
    { id: 1, name: '{"vi-VN":"Đã thu tiền","en-US":"Paid"}' }
];
export const PROJECT_STATUS = [
    { id: 1, name: '{"vi-VN":"Kích hoạt","en-US":"InActive"}' },
    { id: 0, name: '{"vi-VN":"Không kích hoạt","en-US":"Active"}' }
];

export const RECUITMENT_IS_VIEW = [
    { id: 1, name: '{"vi-VN":"Đã xem","en-US":"Viewed"}' },
    { id: 0, name: '{"vi-VN":"Chưa xem","en-US":"View"}' }
];

export const STATUS_SERVICE_REQUEST_CHANGE = [
    { id: 0, name: '{"vi-VN":"Thu thêm khách hàng","en-US":"Need Add Pay"}' },
    { id: 1, name: '{"vi-VN":"Không phát sinh","en-US":"No Fee Change"}' },
    { id: 2, name: '{"vi-VN":"Hoàn tiền khách hàng","en-US":"Cashback"}' }
];

export const TYPE_SERVICE_NOTE = [
    { id: 0, name: '{"vi-VN":"Phiếu mới","en-US":"New Note"}' },
    { id: 1, name: '{"vi-VN":"Phiếu yêu cầu","en-US":"Request Note"}' }
];

export const TYPE_SERVICE = [
    { id: 0, name: '{"vi-VN":"Hoàn tiền","en-US":"Cashback"}' },
    { id: 1, name: '{"vi-VN":"Thu thêm","en-US":"Addition"}' },
    { id: 2, name: '{"vi-VN":"Không thay đổi","en-US":"No Change"}' },
    { id: 3, name: '{"vi-VN":"Hoàn tiền tất cả","en-US":"Cashback all"}' }
];

export const STATUS_NOTE = [
    { id: 0, name: '{"vi-VN":"Đang chờ","en-US":"Waiting"}' },
    { id: 1, name: '{"vi-VN":"Phê duyệt","en-US":"Approved"}' },
    { id: 2, name: '{"vi-VN":"Từ chối","en-US":"Decline"}' }
];

export const PROMOTION_NOTE = [
    { id: 0, name: '{"vi-VN":"Nháp","en-US":"Draft"}' },
    { id: 1, name: '{"vi-VN":"Đang chờ","en-US":"Pending"}' },
    { id: 2, name: '{"vi-VN":"Đã duyệt","en-US":"Approved"}' },
    { id: 3, name: '{"vi-VN":"Từ chối","en-US":"Rejected"}' }
];

export const BOOKING_STATUS = [
    { id: 1, name: '{"vi-VN":"Đang chờ","en-US":"Pending"}' },
    { id: 2, name: '{"vi-VN":"Đã duyệt","en-US":"Approved"}' },
    { id: 3, name: '{"vi-VN":"Từ chối","en-US":"Rejected"}' }
];

export const CELL_WIDTH = {
    isActive: 120,
    isSystem: 120,
    isDefault: 120,
    code: 120,
    thumbnail: 100,
    order: 120,
    endTime: 120,
    startTime: 120,
    dayAgo: 120,
    fullName: 220,
    // 'userName': 100,
    isMultiple: 120,
    gender: 90,
    phoneNumber: 150,
    birthday: 120,
    joinedDate: 120,
    statusName: 120,
    email: 200,
    description: 400
    // 'serviceGroupsName': 400
};

export const ACTIVE_STATE = [
    { id: 0, name: '{"vi-VN":"Không hoạt động","en-US":"InActive"}' },
    { id: 1, name: '{"vi-VN":"Đang hoạt động","en-US":"Active"}' }
];

export const VIEW_STATE = [
    { id: 0, name: '{"vi-VN":"Chưa xem","en-US":"Not View"}' },
    { id: 1, name: '{"vi-VN":"Đã xem","en-US":"Viewed"}' }
];

export const TYPE_PROMOTION = [
    { id: 0, name: '{"vi-VN":"Dịch vụ","en-US":"Service"}' },
    { id: 1, name: '{"vi-VN":"Sản phẩm","en-US":"Product"}' }
    // { id: 2, name: '{"vi-VN":"Đã duyệt","en-US":"Approved"}' }
];

export const TYPE_PROMOTION_READONLY = [
    { id: 0, name: '{"vi-VN":"Dịch vụ","en-US":"Service"}' },
    { id: 1, name: '{"vi-VN":"Sản phẩm","en-US":"Product"}' }
];

export const TYPE_COMMISSIONNE_REPORT = [
    { id: 0, name: '{"vi-VN":"Dịch vụ","en-US":"Service"}' },
    { id: 1, name: '{"vi-VN":"Sản phẩm","en-US":"Product"}' },
    {
        id: 2,
        name: '{"vi-VN":"Thẻ dịch vụ khách hàng","en-US":"CustomerServiceCard"}'
    },
    { id: 3, name: '{"vi-VN":"Thẻ trả trước","en-US":"Prepaid Card"}' }
];

export const TYPE_REVENUE_REPORT = [
    { id: 0, name: '{"vi-VN":"Dịch vụ","en-US":"Service"}' },
    { id: 1, name: '{"vi-VN":"Sản phẩm","en-US":"Product"}' },
    { id: 2, name: '{"vi-VN":"Khách hàng","en-US":"Customer"}' }
];

export const TYPESTAFF_COMMISSIONNE_PRODUCT = [
    { id: 1, name: '{"vi-VN":"Nhân viên tư vấn","en-US":"Counselor"}' },
    { id: 0, name: '{"vi-VN":"Nhân viên bán","en-US":"Salesperson"}' },
    { id: 10, name: '{"vi-VN":"Tất cả","en-US":"All"}' }
];

export const MANAGE_CONFIG = [
    { id: 0, name: '{"vi-VN":"Thủ kho","en-US":"Stocker"}' },
    { id: 1, name: '{"vi-VN":"Kế toán trưởng","en-US":"Chief accountant"}' }
];

export const BANNER_POSITION = [
    { id: 0, name: '{"vi-VN":"Trên","en-US":"Top"}' },
    { id: 1, name: '{"vi-VN":"Dưới","en-US":"Bottom"}' },
    { id: 2, name: '{"vi-VN":"Trái","en-US":"Left"}' },
    { id: 3, name: '{"vi-VN":"Phải","en-US":"Right"}' },
    { id: 4, name: '{"vi-VN":"Giữa","en-US":"Center"}' }
];

export const BANNER_TYPE = [
    { id: 0, name: '{"vi-VN":"Ảnh","en-US":"Image"}' },
    { id: 1, name: '{"vi-VN":"Video", "en-US":"Video"}' }
];

export const MENU_POSITION = [
    { id: 0, name: '{"vi-VN":"Trên","en-US":"Top"}' },
    { id: 1, name: '{"vi-VN":"Dưới","en-US":"Bottom"}' },
    { id: 2, name: '{"vi-VN":"Trái","en-US":"Left"}' },
    { id: 3, name: '{"vi-VN":"Phải","en-US":"Right"}' },
    { id: 4, name: '{"vi-VN":"Giữa","en-US":"Center"}' }
];

export const CUSTOMER_SUPPORT_CATEGORIES = [
    { id: 5, name: '{"vi-VN":"Danh mục phân loại","en-US":"Categories classify"}' },
    { id: 6, name: '{"vi-VN":"Danh mục kết quả","en-US":"Categories result"}' },
    { id: 7, name: '{"vi-VN":"Danh mục kiểm tra","en-US":"Categories checkl list"}' }
];

export const MENU_OBJECT_TYPES = [
    { id: 0, name: '{"vi-VN":"Tùy chỉnh", "en-US":"Custom"}' },
    { id: 1, name: '{"vi-VN":"Chuyên mục tin tức", "en-US":"Category News"}' },
    { id: 2, name: '{"vi-VN":"Tin tức", "en-US":"News"}' },
    {
        id: 3,
        name: '{"vi-VN":"Chuyên mục sản phẩm", "en-US":"Category Product"}'
    },
    { id: 4, name: '{"vi-VN":"Sản phẩm", "en-US":"Product"}' },
    { id: 5, name: '{"vi-VN":"Dịch vụ", "en-US":"Service"}' },
    {
        id: 6,
        name: '{"vi-VN":"Phản hồi khách hàng", "en-US":"Customer feedback"}'
    },
    { id: 7, name: '{"vi-VN":"Nhóm FAQ", "en-US":"FAQ group"}' },
    { id: 8, name: '{"vi-VN":"Album", "en-US":"Album"}' }
];

export const NEWS_STATUS = [
    { id: 0, name: '{"vi-VN":"Nháp","en-US":"Draft"}' },
    { id: 1, name: '{"vi-VN":"Đang chờ","en-US":"Pending"}' },
    { id: 2, name: '{"vi-VN":"Đã duyệt","en-US":"Approved"}' },
    { id: 3, name: '{"vi-VN":"Từ chối","en-US":"Declined"}' }
];

export const NEWS_TYPES = [
    { id: 0, name: '{"vi-VN":"Tin tức", "en-US":"News","ja-JP": "ニュース" }' },
    { id: 1, name: '{"vi-VN":"Sự kiện", "en-US":"Event", "ja-JP": "イベント" }' }
];

export const JOB_TYPES = [
    {
        id: 0,
        name: '{"vi-VN":"Toàn thời gian","en-US":" Fulltime", "ja-JP": "フルタイム"}'
    },
    {
        id: 1,
        name: '{"vi-VN":"Bán thời gian ","en-US":"Parttime", "ja-JP": "パートタイム"}'
    }
];

export const REVIEW_RATE = [
    {
        id: 0,
        name: '{"vi-VN":"Rất hài lòng", "en-US":"Excellent","ja-JP": "優秀な" }'
    },
    { id: 1, name: '{"vi-VN":"Hài lòng", "en-US":"Good","ja-JP": "良い" }' },
    { id: 2, name: '{"vi-VN":"Bình thường", "en-US":"Ok","ja-JP": "Ok" }' },
    {
        id: 3,
        name: '{"vi-VN":"Chưa hài lòng", "en-US":"Poor","ja-JP": "貧しい" }'
    }
];

export const REMINDER_TYPE = [
    {
        id: 0,
        name: '{"vi-VN":"Dịch vụ", "en-US":"Service","ja-JP": "サービス" }'
    },
    { id: 1, name: '{"vi-VN":"Sản phẩm", "en-US":"Products","ja-JP": "製品" }' },
    {
        id: 2,
        name: '{"vi-VN":"Thẻ dịch vụ", "en-US":"Customer service card","ja-JP": "カスタマーサービスカード" }'
    }
];

export const PREPAID_CARD_PAYMENT = [
    {
        id: 0,
        name: '{"vi-VN":"Nạp tiền", "en-US":"Recharge","ja-JP": "充電する" }'
    },
    {
        id: 3,
        name: '{"vi-VN":"Rút tiền từ thẻ DV", "en-US":"Withdraw from service card","ja-JP": "サービスカードから撤退する" }'
    },
    {
        id: 6,
        name: '{"vi-VN":"Chuyển tiền giữa các thẻ", "en-US":"Transfer money between cards","ja-JP": "カード間で送金する" }'
    }
];

export const PREPAID_CARD_STATUS = [
    { id: 0, name: '{"vi-VN":"Chưa thanh toán","en-US":"Unpaid"}' },
    { id: 1, name: '{"vi-VN":"Đã thanh toán","en-US":"Paid"}' },
    { id: 10, name: '{"vi-VN":"Đang thanh toán","en-US":"Not fully paid"}' }
];

export const CARD_STATUS = [
    { id: 0, name: '{"vi-VN":"Hoạt động","en-US":"Active"}' },
    { id: 1, name: '{"vi-VN":"Hết hạn","en-US":"Expired"}' }
];

export const REPORT_STATUS = [
    { id: 3, name: '{"vi-VN":"Hủy","en-US":"Cancel"}' },
    { id: 4, name: '{"vi-VN":"Hoàn thành","en-US":"Sucess"}' }
];

export const REPORT_CARE_STATUS = [
    { id: 1, name: '{"vi-VN":"Đã chăm sóc","en-US":"Đã chăm sóc"}' },
    { id: 0, name: '{"vi-VN":"Chưa chăm sóc","en-US":"Chưa chăm sóc"}' }
];

export const REPORT_CUSTOMER_TYPE = [
    { id: 0, name: '{"vi-VN":"Khách hàng mới","en-US":"New customer"}' },
    {
        id: 1,
        name: '{"vi-VN":"Ngày khách hàng làm dịch vụ","en-US":"Customer service date"}'
    }
];

const newLocal = /[(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/;
export const URL_PATTERN = newLocal;

export const FILE_IMAGE_EXT = ['.jpg', '.png', '.JPEG'];

export const DETAIL_PAYMENT_TYPE = [
    { id: 0, name: '{"vi-VN":"Thẻ trả trước","en-US":"Prepaid_Cards"}' },
    { id: 1, name: '{"vi-VN":"Dịch vụ","en-US":"Service"}' },
    { id: 2, name: '{"vi-VN":"Sản Phẩm","en-US":"Product"}' },
    { id: 3, name: '{"vi-VN":"Rút tiền","en-US":"Withdrawal"}' },
    { id: 4, name: '{"vi-VN":"Yêu cầu dịch vụ","en-US":"RequestService"}' },
    { id: 5, name: '{"vi-VN":"Yêu cầu sản phẩm","en-US":"RequestProduct"}' },
    {
        id: 6,
        name: '{"vi-VN":"Chuyển tiền từ thẻ trả trước này sang trả trước khác","en-US":"Transfers"}'
    },
    { id: 7, name: '{"vi-VN":"Thanh toán thẻ dịch vụ","en-US":"Service Card"}' },
    { id: 8, name: '{"vi-VN":"Hoàn tiền thẻ dịch vụ","en-US":"Refund Service Card"}' },
    { id: 9, name: '{"vi-VN":"Hoàn tiền thẻ trả trước","en-US":"Refund Customer Card"}' }
];

export const TYPE_REMIND_REPORT = [
    { id: 0, name: '{"vi-VN":"Dịch vụ","en-US":"Service"}' },
    { id: 1, name: '{"vi-VN":"Sản phẩm","en-US":"Product"}' }
];

export const IS_REMIND = [
    { id: 0, name: '{"vi-VN":"Chưa gọi","en-US":"Not called yet"}' },
    { id: 1, name: '{"vi-VN":"Đã gọi","en-US":"Called"}' }
];

export const FEEDBACK_RATE = [
    {
        id: 3,
        name: '{"vi-VN":"Rất hài lòng", "en-US":"Excellent","ja-JP": "優秀な" }'
    },
    { id: 2, name: '{"vi-VN":"Hài lòng", "en-US":"Good","ja-JP": "良い" }' },
    { id: 1, name: '{"vi-VN":"Bình thường", "en-US":"Ok","ja-JP": "Ok" }' },
    {
        id: 0,
        name: '{"vi-VN":"Chưa hài lòng", "en-US":"Poor","ja-JP": "貧しい" }'
    }
];

export const STATUS_REFUND_CARD = [
    { id: 0, name: '{"vi-VN":"Bình thường","en-US":"Normal"}' },
    { id: 1, name: '{"vi-VN":"Chờ phê duyệt","en-US":"Waiting for approval"}' },
    { id: 2, name: '{"vi-VN":"Chờ hoàn tiền","en-US":"Waiting for refund"}' },
    { id: 3, name: '{"vi-VN":"Đã hoàn tiền","en-US":"Refunds"}' }
];
